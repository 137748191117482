import axios, { AxiosInstance } from "axios";
import { useMemo } from "react";

import useSpotifyAuth from "./useSpotifyAuth";
import makeSpotifyClient from "../utils/makeSpotifyClient";

const useSpotifyClient = (): AxiosInstance => {
  const { authentication } = useSpotifyAuth();
  const spotifyClient = useMemo(
    () => makeSpotifyClient(authentication?.accessToken),
    [authentication?.accessToken]
  );

  return spotifyClient;
};

export default useSpotifyClient;
