import Constants from "expo-constants";
import {
  NavigatorScreenParams,
  ParamListBase,
  PathConfigMap,
  RouteProp,
} from "@react-navigation/native";
import { CardStyleInterpolators } from "@react-navigation/stack";

import HomeScreen from "../components/screens/HomeScreen";
import ListIcon from "../components/ListIcon";
import NativePlayerScreen from "../components/screens/NativePlayerScreen";
import PlayerScreen from "../components/screens/PlayerScreen";
import PlaylistsScreen from "../components/screens/PlaylistsScreen";
import TabRoutes from "../components/TabRoutes";
import type { TRoute, TabRoute } from "../types/NavigationTypes";

export type TabRoutesParamList = {
  Playlists: undefined;
};
export type RootRoutesParamList = {
  Home: undefined;
  Player: { playlistId: string } | undefined;
  "Native Player": { videoId: string };
  Tabs: NavigatorScreenParams<TabRoutesParamList>;
};
export const tabRoutes: TabRoute<TabRoutesParamList>[] = [
  {
    icon: ListIcon,
    name: "Playlists",
    path: "/playlists",
    screen: PlaylistsScreen,
  },
];
export const rootRoutes: TRoute<RootRoutesParamList, TabRoutesParamList>[] = [
  {
    name: "Home",
    path: "/",
    public: true,
    screen: HomeScreen,
  },
  {
    name: "Player",
    path: "/player/:playlistId?",
    options: {
      gestureDirection: "vertical",
      cardStyleInterpolator: CardStyleInterpolators.forVerticalIOS,
    },
    orientation: "any",
    screen: PlayerScreen,
  },
  {
    name: "Tabs",
    routes: tabRoutes,
    screen: TabRoutes,
  },
  {
    name: "Native Player",
    path: "/native-player/:videoId?",
    public: true,
    screen: NativePlayerScreen,
  },
];

export const getRouteOptions = <ParamsList, TabRoutesParamList = void>(
  route: TRoute<ParamsList, TabRoutesParamList>
) => {
  const titleSuffix = Constants.manifest?.name
    ? `: ${Constants.manifest?.name}`
    : "";
  return {
    title: `${route.name}${titleSuffix}`,
    ...route.options,
  };
};

export const getScreensConfig = <ParamsList, SubParamsList = void>(
  routes: TRoute<ParamsList, SubParamsList>[]
): PathConfigMap<ParamsList> =>
  routes.reduce((config, route) => {
    if ("routes" in route) {
      return {
        ...config,
        [route.name]: {
          screens: getScreensConfig<SubParamsList>(route.routes),
        },
      };
    }
    return {
      ...config,
      [route.name]: route.path,
    };
  }, {});
