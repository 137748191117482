import { useCallback, useState } from "react";

import { PlaylistObject } from "../types/SpotifyTypes";
import usePaginatedQuery from "./usePaginatedQuery";
import useSpotifyClient from "./useSpotifyClient";

interface UserPlaylistOptions {
  initialTrackIndex: number;
  playlistId: string;
}

const useSpotifyUserPlaylists = ({
  initialTrackIndex = 0,
  playlistId,
}: UserPlaylistOptions) => {
  const spotifyClient = useSpotifyClient();
  const getSpotifyPlaylist = useCallback(
    async ({ offset, limit }) => {
      const res = await spotifyClient.get<PlaylistObject>(
        `/v1/playlists/${playlistId}/tracks`,
        { params: { offset, limit } }
      );
      return res.data;
    },
    [spotifyClient]
  );

  const {
    setCurrentItemIndex,
    currentItemIndex,
    currentPageItemIndex,
    ...paginatedQuery
  } = usePaginatedQuery<PlaylistObject>(
    ["playlist", playlistId],
    (options) => getSpotifyPlaylist(options),
    {
      initialItemIndex: initialTrackIndex,
      enabled: !!spotifyClient && !!playlistId,
      limit: 5,
    }
  );

  const total = paginatedQuery.data?.total;
  const setCurrentTrackIndex = useCallback(
    (i: number | ((i: number) => number)) => {
      const newTrackIndex = typeof i === "function" ? i(currentItemIndex) : i;

      if (newTrackIndex < 0 || (total && newTrackIndex >= total)) {
        return;
      }
      return setCurrentItemIndex(newTrackIndex);
    },
    [currentItemIndex, setCurrentItemIndex, total]
  );

  return {
    setCurrentTrackIndex,
    currentTrackIndex: currentItemIndex,
    currentPageTrackIndex: currentPageItemIndex,
    ...paginatedQuery,
  };
};

export default useSpotifyUserPlaylists;
