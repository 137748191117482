import React from "react";
import { Box, Center } from "native-base";

import Button from "../Button";
import useSpotifyAuth from "../../hooks/useSpotifyAuth";
import SpotifyIcon from "../SpotifyIcon";

const LoginScreen: React.FC = () => {
  const { isLoading, login } = useSpotifyAuth();
  return (
    <Box flex={1}>
      <Center flex={1}>
        <Button
          isDisabled={isLoading}
          isLoading={isLoading}
          onPress={() => login()}
          rightIcon={<SpotifyIcon />}
          variant="solid"
        >
          Login with Spotify
        </Button>
      </Center>
    </Box>
  );
};

export default LoginScreen;
