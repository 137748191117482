import React from "react";
import { Box } from "native-base";

import Button from "./Button";
import Card from "./Card";
import SpotifyIcon from "./SpotifyIcon";
import Text from "./Text";
import useSpotifyAuth from "../hooks/useSpotifyAuth";

interface Props {
  onLogin?: () => void;
}

const LoginCard: React.FC<Props> = ({ onLogin }) => {
  const { isLoading, login } = useSpotifyAuth();

  return (
    <Card>
      <Text mt={0} variant="h2">
        Get Started
      </Text>
      <Text variant="p">
        Login with your Spotify account. No extra sign-up required.
      </Text>
      <Button
        isDisabled={isLoading}
        isLoading={isLoading}
        onPress={() => login(onLogin)}
        rightIcon={<SpotifyIcon />}
        variant="solid"
      >
        Login with Spotify
      </Button>
    </Card>
  );
};

export default LoginCard;
