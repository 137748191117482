import { useColorModeValue } from "native-base";

const useContextualColor = (colorKey: string): string => {
  const contextualColorKey = `contextual.${colorKey}`;
  return useColorModeValue(
    `${contextualColorKey}.light`,
    `${contextualColorKey}.dark`
  );
};

export default useContextualColor;
