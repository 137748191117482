interface VideoId {
  kind: string;
  videoId: string;
}

interface SearchVideoResult {
  id: VideoId;
}

export interface SearchListResult {
  items: SearchVideoResult[];
}

export enum YouTubeVideoEmbedState {
  UNSTARTED = -1,
  ENDED = 0,
  PLAYING = 1,
  PAUSED = 2,
  BUFFERING = 3,
  VIDEO_CUED = 5,
}

enum YouTubeVideoPlaybackQuality {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
  HD_720 = "hd720",
  HD_1080 = "hd1080",
  HIGH_RES = "highres",
}

export enum YouTubeVideoEventName {
  ON_READY = "onReady",
  ON_STATE_CHANGE = "onStateChange",
  ON_PLAYBACK_QUALITY_CHANGE = "onPlaybackQualityChange",
  ON_PLAYBACK_RATE_CHANGE = "onPlaybackRateChange",
  ON_ERROR = "onError",
}

enum YouTubeVideoError {
  HTML5_PLAYER_ERROR = 5,
  INVALID_PARAMETER = 2,
  VIDEO_NOT_EMBEDABLE = 101,
  VIDEO_NOT_EMBEDABLE_COPY = 150,
  VIDEO_NOT_FOUND = 100,
}

type EventListener<EventName, EventCallbackData = void> = (
  en: EventName,
  ec: (event: { data: EventCallbackData }) => void
) => void;

type YouTubeVideoEmbedOnError = EventListener<
  YouTubeVideoEventName.ON_ERROR,
  YouTubeVideoError
>;
type YouTubeVideoEmbedOnPlaybackQualityChange = EventListener<
  YouTubeVideoEventName.ON_PLAYBACK_QUALITY_CHANGE,
  YouTubeVideoPlaybackQuality
>;
type YouTubeVideoEmbedOnPlaybackRateChange = EventListener<
  YouTubeVideoEventName.ON_PLAYBACK_RATE_CHANGE,
  number
>;
type YouTubeVideoEmbedOnReady = EventListener<YouTubeVideoEventName.ON_READY>;
type YouTubeVideoEmbedOnStateChange = EventListener<
  YouTubeVideoEventName.ON_STATE_CHANGE,
  YouTubeVideoEmbedState
>;

export interface YouTubeVideoEmbedTarget {
  addEventListener: YouTubeVideoEmbedOnError &
    YouTubeVideoEmbedOnPlaybackQualityChange &
    YouTubeVideoEmbedOnPlaybackRateChange &
    YouTubeVideoEmbedOnReady &
    YouTubeVideoEmbedOnStateChange;
  pauseVideo: () => void;
  playVideo: () => void;
}
