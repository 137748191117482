import { BottomTabNavigationProp } from "@react-navigation/bottom-tabs";
import { useNavigation } from "@react-navigation/native";

import type { TabRoutesParamList } from "../utils/routes";

const useTabNavigation = () => {
  return useNavigation<BottomTabNavigationProp<TabRoutesParamList>>();
};

export default useTabNavigation;
