import React from "react";
import { Box, CircleIcon } from "native-base";
import { SafeAreaView } from "react-native-safe-area-context";
import { BottomTabBarProps } from "@react-navigation/bottom-tabs";

import Tab from "./Tab";
import { tabRoutes } from "../utils/routes";
import useContextualColor from "../hooks/useContextualColor";

const TabNavigation: React.FC<BottomTabBarProps> = ({ navigation, state }) => {
  if (!state) {
    return null;
  }
  const backgroundColor = useContextualColor("tabNavigation.background");
  return (
    <Box backgroundColor={backgroundColor}>
      <SafeAreaView
        edges={["right", "bottom", "left"]}
        style={{
          flexDirection: "row",
          flexWrap: "nowrap",
          justifyContent: "space-between",
        }}
      >
        {state.routes.map((route, index) => (
          <Tab
            icon={tabRoutes[index].icon || CircleIcon}
            isActive={state.index === index}
            key={route.name}
            onPress={() =>
              navigation.navigate({ name: route.name, params: undefined })
            }
          >
            {route.name}
          </Tab>
        ))}
      </SafeAreaView>
    </Box>
  );
};

export default TabNavigation;
