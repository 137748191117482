import React from "react";
import { Box } from "native-base";

// import AuthenticatedComponent from "./AuthenticatedComponent";
import useContextualColor from "../hooks/useContextualColor";
import StatusBar from "./StatusBar";

const Layout: React.FC = ({ children }) => {
  const backgroundColor = useContextualColor("background");
  return (
    <Box bg={backgroundColor} height="100%" width="100%">
      <StatusBar />
      {children}
    </Box>
  );
};

export default Layout;
