import React from "react";
import { Pressable, IPressableProps, Text, IIconProps } from "native-base";

import useContextualColor from "../hooks/useContextualColor";

interface TabProps extends IPressableProps {
  icon: React.ComponentType<IIconProps>;
  isActive?: boolean;
}

const Tab: React.FC<TabProps> = ({
  children,
  icon: Icon,
  isActive,
  ...props
}) => {
  const activeForegroundColor = useContextualColor(
    "tabNavigation.foreground.active"
  );
  const inactiveForegroundColor = useContextualColor(
    "tabNavigation.foreground.inactive"
  );

  const foregroundColor = isActive
    ? activeForegroundColor
    : inactiveForegroundColor;
  return (
    <Pressable alignItems="center" px="5" py="4" {...props}>
      <Icon color={foregroundColor} />
      <Text color={foregroundColor}>{children}</Text>
    </Pressable>
  );
};

export default Tab;
