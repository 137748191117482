import React, { ReactElement } from "react";
import * as ScreenOrientation from "expo-screen-orientation";
import { useFocusEffect } from "@react-navigation/native";

import AuthenticatedScreen from "./AuthenticatedScreen";
import type { TRoute } from "../types/NavigationTypes";

interface Props<RouteParams, SubRouteParams> {
  route: TRoute<RouteParams, SubRouteParams>;
}

const ORIENTATIONS = {
  default: ScreenOrientation.OrientationLock.PORTRAIT_UP,
  landscape: ScreenOrientation.OrientationLock.LANDSCAPE_RIGHT,
  portrait: ScreenOrientation.OrientationLock.PORTRAIT_UP,
};

const Route = <RouteParams, SubRouteParams = any>({
  route,
}: Props<RouteParams, SubRouteParams>): ReactElement => {
  useFocusEffect(() => {
    if (route.orientation === "any") {
      ScreenOrientation.unlockAsync();
      return;
    }
    if (!route.orientation) {
      ScreenOrientation.lockAsync(ORIENTATIONS.default);
      return;
    }
    ScreenOrientation.lockAsync(ORIENTATIONS[route.orientation]);
  });
  if (!route.public) {
    return <AuthenticatedScreen screen={route.screen} />;
  }
  return <route.screen />;
};

export default Route;
