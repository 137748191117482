import React, { useState } from "react";
import { Box, Button as NbButton, PresenceTransition } from "native-base";

import { ButtonProps } from "../types/ThemeTypes";
import { defaultProps } from "../theme/button";
import useContextualColor from "../hooks/useContextualColor";

const Button: React.FC<ButtonProps> = ({ children, ...props }) => {
  const [isActive, setIsActive] = useState(false);
  const buttonColor = useContextualColor(
    `button.${props.variant}.${props.colorScheme}.active.buttonColor`
  );
  const backgroundColor = useContextualColor("background");

  const inactiveBackgroundColor =
    props.variant === "outline" ? "transparent" : buttonColor;
  const activeBackgroundColor =
    props.variant === "outline" ? buttonColor : backgroundColor;

  return (
    <Box>
      <Box
        background={inactiveBackgroundColor}
        position="absolute"
        height="100%"
        left={0}
        top={0}
        width="100%"
        zIndex={-1}
      />
      <PresenceTransition
        initial={{
          opacity: 0,
          scaleX: 0.3,
        }}
        animate={{
          opacity: 1,
          scaleX: 1,
          transition: {
            duration: 150,
          },
        }}
        visible={isActive}
        style={{
          bottom: 0,
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          zIndex: -1,
        }}
      >
        <Box background={activeBackgroundColor} height="100%" width="100%" />
      </PresenceTransition>
      <NbButton
        onHoverIn={() => setIsActive(true)}
        onHoverOut={() => setIsActive(false)}
        {...props}
      >
        {children}
      </NbButton>
    </Box>
  );
};

Button.defaultProps = defaultProps;

export default Button;
