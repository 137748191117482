import "react-native-gesture-handler";
import AppLoading from "expo-app-loading";
import React from "react";
import { NativeBaseProvider } from "native-base";
import { Platform } from "react-native";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { FontAwesome5 } from "@expo/vector-icons";
import {
  Montserrat_400Regular,
  Montserrat_700Bold,
  useFonts,
} from "@expo-google-fonts/montserrat";
import {
  OpenSans_400Regular,
  OpenSans_400Regular_Italic,
  OpenSans_700Bold,
} from "@expo-google-fonts/open-sans";

import Layout from "./src/components/Layout";
import { SpotifyAuthContextProvider } from "./src/context/SpotifyAuthContext";
import Routes from "./src/components/Routes";
import { nativeBaseTheme } from "./src/theme";
import webHotReloader from "./src/utils/webHotReloader";

const queryClient = new QueryClient();

const App: React.FC<void> = () => {
  const [isLoaded] = useFonts({
    "FontAwesome5Free-Solid": FontAwesome5.font["FontAwesome5Free-Solid"],
    Montserrat_400Regular,
    Montserrat_700Bold,
    OpenSans_400Regular,
    OpenSans_400Regular_Italic,
    OpenSans_700Bold,
  });

  if (!isLoaded) {
    return <AppLoading />;
  }

  return (
    <NativeBaseProvider
      config={{ suppressColorAccessibilityWarning: true }}
      theme={nativeBaseTheme}
    >
      <QueryClientProvider client={queryClient}>
        <SpotifyAuthContextProvider>
          <Layout>
            <Routes />
          </Layout>
        </SpotifyAuthContextProvider>
        {Platform.OS === "web" && <ReactQueryDevtools initialIsOpen={false} />}
      </QueryClientProvider>
    </NativeBaseProvider>
  );
};
export default webHotReloader(module)(App);
