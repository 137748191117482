import React from "react";
import { createIcon, IIconProps, useContrastText } from "native-base";
import { Path } from "react-native-svg";

interface SpotifyIconProps extends IIconProps {
  color?: "string";
}

export const SpotifyIcon: React.FC<SpotifyIconProps> = ({
  color,
  ...props
}) => {
  /*
   * Spotify branding requires use of black or white for the icon.
   * This icon takes the passed color, finds the accessible contrast
   * background color, and inverts it to provide either black or
   * white icon color.
   */
  const accessibleBackgroundColor = useContrastText(
    color || "singletons.white"
  );
  const brandedColor =
    accessibleBackgroundColor === "lightText" ? "darkText" : "lightText";
  const Spotify = createIcon({
    viewBox: "0 0 170.05 170.05",
    path: [
      <Path
        d="M85.03,1.28c-46.25,0-83.74,37.49-83.74,83.74c0,46.25,37.49,83.74,83.74,83.74
				c46.25,0,83.74-37.49,83.74-83.74C168.77,38.77,131.28,1.28,85.03,1.28z M123.43,122.07c-1.5,2.47-4.72,3.24-7.18,1.74
				c-19.67-12.02-44.42-14.73-73.57-8.07c-2.81,0.64-5.61-1.12-6.25-3.92c-0.64-2.81,1.11-5.61,3.93-6.25
				c31.9-7.29,59.26-4.15,81.34,9.33C124.16,116.39,124.94,119.61,123.43,122.07z M133.68,99.27c-1.89,3.07-5.91,4.04-8.98,2.15
				c-22.5-13.83-56.82-17.84-83.45-9.76c-3.45,1.04-7.1-0.9-8.15-4.35c-1.04-3.45,0.91-7.09,4.35-8.14
				c30.42-9.23,68.23-4.76,94.07,11.13C134.6,92.18,135.56,96.2,133.68,99.27z M134.56,75.52c-26.99-16.03-71.52-17.5-97.29-9.68
				c-4.14,1.26-8.51-1.08-9.77-5.22c-1.25-4.14,1.08-8.51,5.22-9.77c29.58-8.98,78.76-7.24,109.83,11.2
				c3.72,2.21,4.94,7.02,2.74,10.73C143.09,76.5,138.27,77.73,134.56,75.52z"
      />,
    ],
  });

  return <Spotify {...props} color={brandedColor} />;
};

export default SpotifyIcon;
