import { contextual } from "./colors";
import {
  BaseStyleArguments,
  ButtonProps,
  ButtonPropsModified,
} from "../types/ThemeTypes";

export const defaultProps: Required<ButtonPropsModified> = {
  borderRadius: 0,
  colorScheme: "primary",
  px: 4,
  py: 1.5,
  size: "lg",
  variant: "outline",
};

type ButtonBaseStyleArguments = BaseStyleArguments<ButtonProps>;
const ButtonTheme = {
  defaultProps,
  baseStyle: ({
    colorMode,
    colorScheme,
    variant,
  }: ButtonBaseStyleArguments) => {
    const buttonColorScheme = colorScheme || defaultProps.colorScheme;
    const buttonVariant = variant || defaultProps.variant;
    const activeButtonColor = `contextual.button.${buttonVariant}.${buttonColorScheme}.active.buttonColor.${colorMode}`;

    return {
      borderColor: activeButtonColor,
    };
  },
  variants: {
    outline: ({
      colorMode,
      colorScheme,
      variant,
    }: ButtonBaseStyleArguments) => {
      const buttonColorScheme = colorScheme || defaultProps.colorScheme;
      const buttonVariant = variant || defaultProps.variant;
      const { active, inactive } =
        contextual.button[buttonVariant][buttonColorScheme];

      const activeButtonColor = active.buttonColor[colorMode];
      const activeTextColor = active.textColor[colorMode];
      const inactiveButtonColor = inactive.buttonColor[colorMode];
      const inactiveTextColor = inactive.textColor[colorMode];
      const bg = "transparent";

      return {
        bg,
        borderColor: inactiveButtonColor,
        _focusVisible: {
          bg,
          borderColor: activeButtonColor,
          _text: {
            color: activeTextColor,
          },
        },
        _hover: {
          bg,
          borderColor: activeButtonColor,
          _text: {
            color: activeTextColor,
          },
        },
        _pressed: {
          bg,
          borderColor: activeButtonColor,
          _text: {
            color: activeTextColor,
          },
        },
        _text: {
          color: inactiveTextColor,
        },
      };
    },
    solid: ({ colorMode, colorScheme, variant }: ButtonBaseStyleArguments) => {
      const buttonColorScheme = colorScheme || defaultProps.colorScheme;
      const buttonVariant = variant || defaultProps.variant;

      const colorSchemeKey = `contextual.button.${buttonVariant}.${buttonColorScheme}`;
      const activeButtonColor = `${colorSchemeKey}.active.buttonColor.${colorMode}`;
      const activeTextColor = `${colorSchemeKey}.active.textColor.${colorMode}`;
      const inactiveButtonColor = `${colorSchemeKey}.inactive.buttonColor.${colorMode}`;
      const inactiveTextColor = `${colorSchemeKey}.inactive.textColor.${colorMode}`;
      const bg = "transparent";

      return {
        bg,
        borderColor: inactiveButtonColor,
        borderWidth: 1,
        _focusVisible: {
          bg,
          borderColor: activeButtonColor,
          _text: {
            color: activeTextColor,
          },
        },
        _hover: {
          bg,
          borderColor: activeButtonColor,
          _text: {
            color: activeTextColor,
          },
        },
        _pressed: {
          bg,
          borderColor: activeButtonColor,
          _text: {
            color: activeTextColor,
          },
        },
        _text: {
          color: inactiveTextColor,
        },
      };
    },
  },
};

export default ButtonTheme;
