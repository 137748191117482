import axios from "axios";
import Constants from "expo-constants";

// TODO: deteremine why env variables are initially undefined
const makeYouTubeClient = () =>
  axios.create({
    baseURL: "https://www.googleapis.com/youtube/v3",
    timeout: 3000,
    params: {
      key: Constants.manifest?.extra?.SPOTIVISION_GOOGLE_CLIENT_ID || "",
    },
  });

export default makeYouTubeClient;
