import { BottomTabNavigationProp } from "@react-navigation/bottom-tabs";
import { useNavigation } from "@react-navigation/native";

import type { RootRoutesParamList } from "../utils/routes";

const useRootStackNavigation = () => {
  return useNavigation<BottomTabNavigationProp<RootRoutesParamList>>();
};

export default useRootStackNavigation;
