import React from "react";

import useRootStackRoute from "../../hooks/useRootStackRoute";
import YouTubeVideoEmbed from "../YouTubeVideoEmbed";

const NativePlayerScreen: React.FC = () => {
  const { params } = useRootStackRoute<"Native Player">();

  if (!params.videoId) {
    return null;
  }

  return <YouTubeVideoEmbed videoId={params.videoId} />;
};

export default NativePlayerScreen;
