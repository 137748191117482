import React from "react";
import {
  HStack,
  Image,
  Pressable,
  ScrollView,
  Spinner,
  VStack,
} from "native-base";
import { SafeAreaView } from "react-native-safe-area-context";

import ScreenContainer from "./ScreenContainer";
import Text from "./Text";
import useSpotifyUserPlaylists from "../hooks/useSpotifyUserPlaylists";

interface Props {
  onSelect: (playlistId: string) => void;
}

const SpotifyUserPlaylists = ({ onSelect }: Props) => {
  const { isLoading, data, error } = useSpotifyUserPlaylists();

  if (isLoading) {
    return <Spinner />;
  }
  if (error) {
    return <Text>Oh no! Something went wrong!</Text>;
  }

  return (
    <ScrollView>
      <SafeAreaView>
        <ScreenContainer>
          <Text variant="h1">Playlists</Text>
          <VStack mb="4" space="8">
            {data?.items.map((playlist) => (
              <Pressable
                accessibilityLabel={`play ${playlist.name}`}
                key={playlist.id}
                onPress={() => {
                  onSelect(playlist.id);
                }}
              >
                <HStack alignItems="center" space={4}>
                  {playlist.images[0]?.url && (
                    <Image
                      alt={`${playlist.name} thumnail`}
                      size="16"
                      source={{ uri: playlist.images[0].url }}
                    />
                  )}
                  <Text my={0} numberOfLines={2} variant="h4">
                    {playlist.name}
                  </Text>
                </HStack>
              </Pressable>
            ))}
          </VStack>
        </ScreenContainer>
      </SafeAreaView>
    </ScrollView>
  );
};

export default SpotifyUserPlaylists;
