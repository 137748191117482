import { extendTheme } from "native-base";

import Button from "./button";
import colors, { nativeBaseColors } from "./colors";
import Text from "./text";
import * as typography from "./typography";

export const nativeBaseTheme = extendTheme({
  colors,
  components: {
    Button,
    Text,
  },
  config: {
    useSystemColorMode: false,
    initialColorMode: "dark",
    strictMode: "off",
  },
  ...typography,
});

export const reactNavigationTheme = {
  dark: true,
  colors: nativeBaseColors,
};
