import { RouteProp, useRoute } from "@react-navigation/native";

import type { RootRoutesParamList } from "../utils/routes";

type ScreenRouteProp<ScreenName extends keyof RootRoutesParamList> = RouteProp<
  RootRoutesParamList,
  ScreenName
>;

const useRootStackRoute = <ScreenName extends keyof RootRoutesParamList>() => {
  return useRoute<ScreenRouteProp<ScreenName>>();
};

export default useRootStackRoute;
