export const lineHeights = {
  none: 1,
  shorter: 1.25,
  short: 1.375,
  base: 1.5,
  tall: 1.625,
  taller: "2",
  3: "12px",
  4: "16px",
  5: "20px",
  6: "24px",
  7: "28px",
  8: "32px",
  9: "36px",
  10: "46px", // h1 mobile
  11: "112px", // h1 lg
};

export const fontConfig = {
  Montserrat: {
    400: {
      normal: "Montserrat_400Regular",
    },
    700: {
      normal: "Montserrat_700Bold",
    },
  },
  "Open Sans": {
    400: {
      italic: "OpenSans_400Regular_Italic",
      normal: "OpenSans_400Regular",
    },
    700: {
      normal: "OpenSans_700Bold",
    },
  },
};

export const fonts = {
  heading: `Montserrat`,
  body: `Open Sans`,
};

export const fontSizes = {
  xs: 14, // h6 mobile
  sm: 18, // h5 mobile & h6 normal & lg
  md: 20, // h3 mobile & h4 mobile
  lg: 22, // h5 normal & lg
  xl: 28, // h3 normal & h4 normal & lg
  "2xl": 34, // h2 mobile & h3 lg
  "3xl": 44, // h2 normal & h1 mobile
  "4xl": 52, // h2 lg
  "5xl": 86, // h1 normal
  "6xl": 101, // h1 lg
};
