import axios, { AxiosInstance } from "axios";
import { TokenResponse } from "expo-auth-session";

const makeSpotifyClient = (accessToken = ""): AxiosInstance =>
  axios.create({
    baseURL: "https://api.spotify.com/",
    timeout: 3000,
    headers: { Authorization: `Bearer ${accessToken}` },
  });

export default makeSpotifyClient;
