import { theme } from "native-base";

const primaryBase = "#ffd34d"; // usual primary
export const primary = {
  50: "#fff9db",
  100: "#ffecaf",
  200: "#ffdf7f",
  300: primaryBase,
  400: "#ffc61e",
  500: "#e6ad06",
  600: "#b38600",
  700: "#806000",
  800: "#4e3a00",
  900: "#1d1300",
};

const secondaryBase = "#465c9f"; // usual secondary
export const secondary = {
  50: "#e8f0ff",
  100: "#c8d1ed",
  200: "#a5b3db",
  300: "#8294c9",
  400: "#6075b9",
  500: secondaryBase, // light blue
  600: "#36487d",
  700: "#2c409a", // dark blue
  800: "#141f39",
  900: "#050a1a",
};

export const singletons = {
  black: "#19191a",
  darkText: "#19191a",
  lightText: "#eeeeee",
  white: "#eeeeee",
};

export const contextual = {
  background: {
    dark: singletons.black,
    light: singletons.white,
  },
  button: {
    outline: {
      primary: {
        active: {
          buttonColor: {
            dark: primaryBase,
            light: primaryBase,
          },
          textColor: {
            dark: singletons.black,
            light: singletons.black,
          },
        },
        inactive: {
          buttonColor: {
            dark: primaryBase,
            light: primaryBase,
          },
          textColor: {
            dark: primaryBase,
            light: primaryBase,
          },
        },
      },
      secondary: {
        active: {
          buttonColor: {
            dark: secondary[700],
            light: secondary[700],
          },
          textColor: {
            dark: singletons.white,
            light: singletons.white,
          },
        },
        inactive: {
          buttonColor: {
            dark: secondary[700],
            light: secondary[700],
          },
          textColor: {
            dark: singletons.white,
            light: singletons.white,
          },
        },
      },
    },
    solid: {
      primary: {
        active: {
          buttonColor: {
            dark: primaryBase,
            light: primaryBase,
          },
          textColor: {
            dark: primaryBase,
            light: primaryBase,
          },
        },
        inactive: {
          buttonColor: {
            dark: primaryBase,
            light: primaryBase,
          },
          textColor: {
            dark: singletons.black,
            light: singletons.black,
          },
        },
      },
      secondary: {
        active: {
          buttonColor: {
            dark: secondary[700],
            light: secondary[700],
          },
          textColor: {
            dark: singletons.white,
            light: singletons.white,
          },
        },
        inactive: {
          buttonColor: {
            dark: secondary[700],
            light: secondary[700],
          },
          textColor: {
            dark: singletons.white,
            light: singletons.white,
          },
        },
      },
    },
  },
  card: {
    background: {
      dark: theme.colors.dark[100],
      light: theme.colors.dark[100],
    },
  },
  foreground: {
    dark: singletons.white,
    light: singletons.black,
  },
  h1: {
    dark: primary[400],
    light: primary[400],
  },
  h2: {
    dark: secondaryBase,
    light: secondaryBase,
  },
  h3: {
    dark: primaryBase,
    light: primaryBase,
  },
  h4: {
    dark: singletons.white,
    light: singletons.black,
  },
  h5: {
    dark: primaryBase,
    light: primaryBase,
  },
  tabNavigation: {
    background: {
      dark: "#000000",
      light: singletons.white,
    },
    foreground: {
      inactive: {
        dark: singletons.white,
        light: singletons.black,
      },
      active: {
        dark: primaryBase,
        light: primaryBase,
      },
    },
  },
  youTubeVideoEmbed: {
    background: {
      dark: "#000000",
      light: "#000000",
    },
  },
};

export const nativeBaseColors = {
  primary: primaryBase,
  background: contextual.background.dark,
  border: singletons.white,
  card: singletons.white,
  notification: secondaryBase,
  text: contextual.foreground.dark,
};

export default {
  contextual,
  primary,
  secondary,
  singletons,
};
