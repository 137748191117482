import React from "react";

import useRootStackNavigation from "../../hooks/useRootStackNavigation";
import SpotifyUserPlaylists from "../SpotifyUserPlaylists";

const PlaylistsScreen: React.FC = () => {
  const { navigate } = useRootStackNavigation();

  return (
    <SpotifyUserPlaylists
      onSelect={(playlistId: string) =>
        navigate({ name: "Player", params: { playlistId } })
      }
    />
  );
};

export default PlaylistsScreen;
