import React, { useEffect, useRef, useState } from "react";
import {
  Center,
  Box,
  HStack,
  Icon,
  IconButton,
  PresenceTransition,
  Pressable,
  Spinner,
} from "native-base";
import { useDebounceFn } from "ahooks";
import { FontAwesome5 } from "@expo/vector-icons";
import YouTubeEmbed from "react-youtube";

import useContextualColor from "../hooks/useContextualColor";
import {
  YouTubeVideoEmbedTarget,
  YouTubeVideoEventName,
  YouTubeVideoEmbedState,
} from "../types/YouTubeTypes";

const AUTOPLAY = true;

interface Props {
  isNextDisabled?: boolean;
  isPreviousDisabled?: boolean;
  onNext?: () => void;
  onPrevious?: () => void;
  onVideoEnded?: () => void;
  videoId: string;
}

const YouTubeVideoEmbed: React.FC<Props> = ({
  isNextDisabled = false,
  isPreviousDisabled = false,
  onNext = () => {},
  onPrevious = () => {},
  onVideoEnded = () => {},
  videoId,
}: Props) => {
  const backgroundColor = useContextualColor("youTubeVideoEmbed.background");
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [controlsVisible, setControlsVisible] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(AUTOPLAY);
  const containerRef = useRef<typeof Box | null>(null);
  const videoRef = useRef<YouTubeVideoEmbedTarget | null>(null);
  const hideVideoControlsTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const { run: handleTempShowControls } = useDebounceFn(
    () => {
      if (hideVideoControlsTimeoutRef.current) {
        clearTimeout(hideVideoControlsTimeoutRef.current);
      }
      hideVideoControlsTimeoutRef.current = setTimeout(() => {
        setControlsVisible(false);
      }, 2000);
      setControlsVisible(true);
    },
    {
      leading: true,
      maxWait: 500,
    }
  );

  useEffect(() => {
    return () => {
      if (hideVideoControlsTimeoutRef.current) {
        clearTimeout(hideVideoControlsTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener(
        YouTubeVideoEventName.ON_STATE_CHANGE,
        ({ data }) => {
          if (data === YouTubeVideoEmbedState.ENDED) {
            onVideoEnded();
          }
        }
      );
    }
  });

  const handlePressPause = () => {
    videoRef.current?.pauseVideo();
    setIsVideoPlaying(false);
  };
  const handlePressPlay = () => {
    videoRef.current?.playVideo();
    setIsVideoPlaying(true);
  };
  const handleVideoReady = (e: Event) => {
    // typecasting due to properly declare additional YouTube embed API properties
    videoRef.current = e.target as unknown as YouTubeVideoEmbedTarget;
    setIsVideoReady(true);
  };

  return (
    <Box backgroundColor={backgroundColor} flex={1} ref={containerRef}>
      <Center flex={1}>
        <Box alignItems="stretch" pb="56.25%" height={0} width="100%">
          <YouTubeEmbed
            // classname originates from index.html to overcome limitation of react-youtube package
            containerClassName="youTubeWebEmbedContainer"
            opts={{
              height: "100%",
              playerVars: {
                autoplay: AUTOPLAY ? 1 : 0,
                color: "white",
                controls: 0,
                enablejsapi: 1,
                fs: 0,
                iv_load_policy: 3,
                modestbranding: 1,
                origin: window.location.origin,
                playsinline: 1,
                rel: 0,
              },
              width: "100%",
            }}
            onReady={handleVideoReady}
            videoId={videoId}
          />
        </Box>
        <Box height="100%" left={0} position="absolute" top={0} width="100%">
          <Center flex={1}>
            <Pressable
              alignItems="stretch"
              height="100%"
              justifyContent="stretch"
              left={0}
              position="absolute"
              onPress={handleTempShowControls}
              top={0}
              width="100%"
            >
              <div
                onMouseMove={handleTempShowControls}
                style={{ flexGrow: 1 }}
              />
            </Pressable>
            {!isVideoReady ? (
              <Center flex={1}>
                <Spinner />
              </Center>
            ) : (
              <PresenceTransition
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 250,
                  },
                }}
                initial={{
                  opacity: 0,
                }}
                style={{ width: "100%" }}
                visible={!isVideoPlaying || controlsVisible}
              >
                <HStack justifyContent="space-between" width="100%">
                  <IconButton
                    accessibilityLabel="go to previous song"
                    icon={<Icon as={FontAwesome5} name="backward" />}
                    disabled={isPreviousDisabled}
                    onPress={onPrevious}
                  />
                  <IconButton
                    accessibilityLabel={isVideoPlaying ? "pause" : "play"}
                    icon={
                      <Icon
                        as={FontAwesome5}
                        name={isVideoPlaying ? "pause" : "play"}
                      />
                    }
                    onPress={
                      isVideoPlaying ? handlePressPause : handlePressPlay
                    }
                  />
                  <IconButton
                    accessibilityLabel="go to next song"
                    disabled={isNextDisabled}
                    icon={<Icon as={FontAwesome5} name="forward" />}
                    onPress={onNext}
                  />
                </HStack>
              </PresenceTransition>
            )}
          </Center>
        </Box>
      </Center>
    </Box>
  );
};

export default YouTubeVideoEmbed;
