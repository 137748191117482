import React from "react";

import useSpotifyAuth from "../hooks/useSpotifyAuth";

interface Props {
  authenticatedComponent: React.ComponentType;
  unauthenticatedComponent?: React.ComponentType;
}

const AuthenticatedComponent: React.FC<Props> = ({
  authenticatedComponent: AuthenticatedComponent,
  unauthenticatedComponent: UnauthenticatedComponent,
}) => {
  const { isAuthenticated } = useSpotifyAuth();

  if (!isAuthenticated) {
    return UnauthenticatedComponent ? <UnauthenticatedComponent /> : null;
  }
  return <AuthenticatedComponent />;
};

export default AuthenticatedComponent;
