import React, { useCallback, createContext, useState } from "react";
import { Platform } from "react-native";
import {
  makeRedirectUri,
  TokenResponse,
  useAuthRequest,
  useAutoDiscovery,
} from "expo-auth-session";
import Constants from "expo-constants";
import * as WebBrowser from "expo-web-browser";

const useProxy = Platform.OS !== "web";
const redirectUri = makeRedirectUri({
  path: "/auth/",
  scheme: "spotivision://",
  useProxy,
});
WebBrowser.maybeCompleteAuthSession();

type loginCallback = () => void;
interface TSpotifyAuthContext {
  authentication: TokenResponse | null;
  error: Error | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  login: (onLogin?: loginCallback) => Promise<void>;
}

export const SpotifyAuthContext = createContext<TSpotifyAuthContext>({
  authentication: null,
  error: null,
  isAuthenticated: false,
  isLoading: false,
  login: () => Promise.reject(),
});

export const SpotifyAuthContextProvider: React.FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [request, response, promptAsync] = useAuthRequest(
    {
      clientId: Constants.manifest?.extra?.SPOTIVISION_SPOTIFY_CLIENT_ID || "",
      redirectUri,
      responseType: "token",
      scopes: [
        "playlist-read-collaborative",
        "playlist-read-private",
        "user-read-private",
      ],
    },
    {
      authorizationEndpoint: "https://accounts.spotify.com/authorize",
    }
  );
  const login = useCallback(
    async (onLogin?: loginCallback) => {
      if (!isLoading && request && !response) {
        try {
          setIsLoading(true);
          const { type } = await promptAsync({
            useProxy,
          });
          if (type === "success") {
            onLogin && onLogin();
          }
        } finally {
          setIsLoading(false);
        }
      }
    },
    [isLoading, request, response, setIsLoading]
  );

  let authentication = null;
  let error = null;
  let isAuthenticated = false;
  if (response?.type === "success" || response?.type === "error") {
    authentication = response.authentication;
    error = response.error || error;
    isAuthenticated = !!response?.authentication;
  }

  return (
    <SpotifyAuthContext.Provider
      value={{
        authentication,
        error,
        isAuthenticated,
        isLoading,
        login,
      }}
    >
      {children}
    </SpotifyAuthContext.Provider>
  );
};
