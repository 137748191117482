import { WebView } from "react-native-webview";
import React, { useEffect, useState } from "react";
import { ActivityIndicator, Button, Text, View } from "react-native";

import useSpotifyPlaylist from "../hooks/useSpotifyPlaylist";
import useYouTubeSearchList from "../hooks/useYouTubeSearchList";
import YouTubeVideoEmbed from "./YouTubeVideoEmbed";

interface Props {
  playlistId: string;
}

const SpotifyPlaylist = ({ playlistId }: Props) => {
  const {
    isLoading,
    data,
    error,
    currentPageTrackIndex,
    setCurrentTrackIndex,
  } = useSpotifyPlaylist({
    initialTrackIndex: 0,
    playlistId,
  });

  const currentTrack = data?.items.length
    ? data?.items[currentPageTrackIndex].track
    : null;
  const trackSearch = currentTrack
    ? `${currentTrack.name} ${currentTrack.artists[0].name}`
    : "";
  const { data: youTubeSearchResult } = useYouTubeSearchList(trackSearch);
  const currentVideoId = youTubeSearchResult?.items[0].id.videoId;

  if (isLoading) {
    return <ActivityIndicator />;
  }
  if (error || !data) {
    return <Text>Oh no! Something went wrong!</Text>;
  }

  return (
    <>
      {/* <Text>{`Current Track: ${trackSearch}`}</Text>
      {data.items.map((item) => (
        <View key={item.track.id}>
          <Text>{item.track.name}</Text>
        </View>
      ))} */}
      {currentVideoId && (
        <YouTubeVideoEmbed
          isPreviousDisabled={currentPageTrackIndex === 0}
          onNext={() => setCurrentTrackIndex((i) => i + 1)}
          onPrevious={() => setCurrentTrackIndex((i) => i - 1)}
          onVideoEnded={() => setCurrentTrackIndex((i) => i + 1)}
          videoId={currentVideoId}
        />
      )}
    </>
  );
};

export default SpotifyPlaylist;
