import React from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";

import AuthenticatedScreen from "./AuthenticatedScreen";
import {
  getRouteOptions,
  TabRoutesParamList,
  tabRoutes,
} from "../utils/routes";
import TabNavigation from "./TabNavigation";

const Tab = createBottomTabNavigator<TabRoutesParamList>();
const TabRoutes: React.FC = () => {
  return (
    <Tab.Navigator
      screenOptions={{
        headerShown: false,
      }}
      tabBar={(props) => {
        return <TabNavigation {...props} />;
      }}
    >
      {tabRoutes.map((route) => {
        return (
          <Tab.Screen
            key={route.name}
            name={route.name}
            options={getRouteOptions<TabRoutesParamList>(route)}
          >
            {() =>
              route.public ? (
                <route.screen />
              ) : (
                <AuthenticatedScreen screen={route.screen} />
              )
            }
          </Tab.Screen>
        );
      })}
    </Tab.Navigator>
  );
};

export default TabRoutes;
