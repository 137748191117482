import { useCallback } from "react";
import { useQuery } from "react-query";
import Constants from "expo-constants";

import makeYouTubeClient from "../utils/makeYouTubeClient";
import { SearchListResult } from "../types/YouTubeTypes";

const useYouTubeSearchList = (query?: string) => {
  const getYouTubeSearchList =
    useCallback(async (): Promise<SearchListResult> => {
      const youTubeClient = makeYouTubeClient();

      // enable overriding returned results for local development
      if (Constants.manifest?.extra?.SPOTIVISION_LOCAL_DEV_VIDEO_ID) {
        return {
          items: [
            {
              id: {
                kind: "",
                videoId:
                  Constants.manifest.extra.SPOTIVISION_LOCAL_DEV_VIDEO_ID,
              },
            },
          ],
        };
      }

      const res = await youTubeClient?.get<SearchListResult>("/search", {
        params: {
          part: "snippet",
          q: query,
        },
      });
      return res?.data || { items: [] };
    }, [query]);

  return useQuery(["youTubeSearch", query], getYouTubeSearchList, {
    enabled: !!query,
    retry: false,
  });
};

export default useYouTubeSearchList;
