import React, { useCallback } from "react";
import { useFocusEffect } from "@react-navigation/native";
import { Box, Icon, IconButton } from "native-base";
import { FontAwesome5 } from "@expo/vector-icons";

import SpotifyPlaylist from "../SpotifyPlaylist";
import useContextualColor from "../../hooks/useContextualColor";
import useRootStackNavigation from "../../hooks/useRootStackNavigation";
import useTabNavigation from "../../hooks/useTabNavigation";
import useRootStackRoute from "../../hooks/useRootStackRoute";

const PlayerScreen: React.FC = () => {
  const backgroundColor = useContextualColor("youTubeVideoEmbed.background");
  const { jumpTo } = useTabNavigation();
  const { canGoBack, goBack } = useRootStackNavigation();
  const { navigate } = useTabNavigation();
  const { params } = useRootStackRoute<"Player">();

  useFocusEffect(
    useCallback(() => {
      if (!params?.playlistId) {
        jumpTo("Playlists");
      }
    }, [jumpTo, params])
  );

  if (!params?.playlistId) {
    return null;
  }

  return (
    <Box backgroundColor={backgroundColor} flex={1}>
      <SpotifyPlaylist playlistId={params.playlistId} />
      <Box left={0} position="absolute" top={0} safeArea>
        <IconButton
          icon={<Icon as={FontAwesome5} name="chevron-down" />}
          onPress={() => {
            if (canGoBack()) {
              goBack();
              return;
            }
            navigate("Playlists");
          }}
        />
      </Box>
    </Box>
  );
};

export default PlayerScreen;
