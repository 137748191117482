import {
  QueryKey,
  QueryFunction,
  UseQueryOptions,
  useQuery,
} from "react-query";
import { useState } from "react";

interface PaginationOptions {
  limit: number;
  initialItemIndex: number;
}
type QueryOptions<TQueryFnData> = PaginationOptions &
  UseQueryOptions<TQueryFnData>;
type QueryFunctionOffsetLimit<TQueryFnData> = (options: {
  offset: number;
  limit: number;
}) => TQueryFnData | Promise<TQueryFnData>;

const usePaginatedQuery = <TQueryFnData>(
  queryKey: QueryKey,
  queryFnOffsetLimit: QueryFunctionOffsetLimit<TQueryFnData>,
  { initialItemIndex, limit, ...options }: QueryOptions<TQueryFnData>
) => {
  const [currentItemIndex, setCurrentItemIndex] = useState(initialItemIndex);
  const page = Math.floor(currentItemIndex / limit);
  const offset = page * limit;
  const fetchFn: QueryFunction<TQueryFnData> = (options: object) =>
    queryFnOffsetLimit({ ...options, offset, limit });
  const key =
    typeof queryKey === "object" ? [...queryKey, page] : [queryKey, page];
  const currentPageItemIndex = currentItemIndex - page * limit;

  return {
    currentItemIndex,
    currentPageItemIndex,
    setCurrentItemIndex,
    ...useQuery<TQueryFnData>(key, fetchFn, {
      ...options,
      keepPreviousData: true,
    }),
  };
};

export default usePaginatedQuery;
