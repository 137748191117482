import * as React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { NavigationContainer } from "@react-navigation/native";

import Route from "./Route";
import {
  getRouteOptions,
  getScreensConfig,
  RootRoutesParamList,
  TabRoutesParamList,
  rootRoutes,
} from "../utils/routes";
import { reactNavigationTheme } from "../theme";

declare global {
  namespace ReactNavigation {
    interface RootParamList extends RootRoutesParamList {}
  }
}
const rootScreensConfig = getScreensConfig<
  RootRoutesParamList,
  TabRoutesParamList
>(rootRoutes);
const rootLinking = {
  prefixes: ["", ""],
  config: {
    screens: rootScreensConfig,
  },
};

const Stack = createStackNavigator<RootRoutesParamList>();
const Routes: React.FC = () => {
  return (
    <NavigationContainer linking={rootLinking} theme={reactNavigationTheme}>
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
        }}
      >
        {rootRoutes.map((route) => {
          return (
            <Stack.Screen
              key={route.name}
              name={route.name}
              options={getRouteOptions<RootRoutesParamList, TabRoutesParamList>(
                route
              )}
            >
              {() => <Route route={route} />}
            </Stack.Screen>
          );
        })}
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default Routes;
