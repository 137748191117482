import React from "react";
import { Box, IBoxProps, ScrollView } from "native-base";

const ScreenContainer: React.FC<IBoxProps> = ({ children, ...props }) => (
  <Box px="4" {...props}>
    {children}
  </Box>
);

export default ScreenContainer;
