import { GestureResponderEvent } from "react-native";
import React, { useCallback } from "react";
import { useLinkProps } from "@react-navigation/native";

import type { ButtonProps } from "../types/ThemeTypes";
import type { LinkProps } from "../types/NavigationTypes";
import Button from "./Button";

type Props = ButtonProps & LinkProps;

const ButtonLink: React.FC<Props> = ({
  action,
  onPress,
  to,
  ...ButtonProps
}) => {
  const { onPress: onLinkPress } = useLinkProps({
    to,
    action,
  });
  const onButtonLinkPress = useCallback(
    (e: GestureResponderEvent) => {
      onPress && onPress(e);
      onLinkPress();
    },
    [onLinkPress, onPress]
  );

  return <Button onPress={onButtonLinkPress} {...ButtonProps} />;
};

export default ButtonLink;
