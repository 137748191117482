import React from "react";
import { StatusBar as RnStatusBar } from "react-native";
import { useColorMode } from "native-base";

const StatusBar: React.FC = () => {
  const { colorMode } = useColorMode();
  return (
    <RnStatusBar
      barStyle={colorMode === "dark" ? "light-content" : "dark-content"}
    />
  );
};

export default StatusBar;
