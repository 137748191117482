import React from "react";
import { Text as NbText } from "native-base";

import { TextProps } from "../types/ThemeTypes";

const Text: React.FC<TextProps> = ({ children, ...props }) => (
  <NbText {...props}>{children}</NbText>
);

export default Text;
