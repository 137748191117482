import React, { useCallback } from "react";
import { Box, Center, ScrollView } from "native-base";
import { SafeAreaView } from "react-native-safe-area-context";

import AuthenticatedComponent from "../AuthenticatedComponent";
import ButtonLink from "../ButtonLink";
import LoginCard from "../LoginCard";
import ScreenContainer from "../ScreenContainer";
import Text from "../Text";
import useRootStackNavigation from "../../hooks/useRootStackNavigation";

const HomeScreen: React.FC = () => {
  const { navigate } = useRootStackNavigation();

  const onLogin = useCallback(() => {
    navigate("Tabs", { params: undefined, screen: "Playlists" });
  }, [navigate]);

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <Center flex={1}>
        <ScreenContainer>
          <Text variant="h1">Spotivision</Text>
          <AuthenticatedComponent
            authenticatedComponent={() => (
              <ButtonLink
                to={{ screen: "Tabs", params: { screen: "Playlists" } }}
                variant="solid"
              >
                Go to Your Playlists
              </ButtonLink>
            )}
            unauthenticatedComponent={() => <LoginCard onLogin={onLogin} />}
          />
        </ScreenContainer>
      </Center>
    </SafeAreaView>
  );
};

export default HomeScreen;
