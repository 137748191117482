import React from "react";

import AuthenticatedComponent from "./AuthenticatedComponent";
import LoginScreen from "./screens/LoginScreen";

interface Props {
  screen: React.ComponentType;
}

const AuthenticatedScreen: React.FC<Props> = ({ screen: Screen }) => {
  return (
    <AuthenticatedComponent
      authenticatedComponent={Screen}
      unauthenticatedComponent={LoginScreen}
    />
  );
};

export default AuthenticatedScreen;
