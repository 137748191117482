import { useCallback, useMemo } from "react";
import { useQuery } from "react-query";

import { SimplifiedPlaylistObject } from "../types/SpotifyTypes";
import useSpotifyClient from "./useSpotifyClient";

interface SpotifyUserPlaylistsResponse {
  items: SimplifiedPlaylistObject[];
}

const useSpotifyUserPlaylists = () => {
  const spotifyClient = useSpotifyClient();
  const getSpotifyUserPlaylists = useCallback(async () => {
    const res = await spotifyClient?.get<SpotifyUserPlaylistsResponse>(
      "/v1/me/playlists"
    );
    return res?.data || { items: [] };
  }, [spotifyClient]);

  return useQuery<SpotifyUserPlaylistsResponse>(
    "playlists",
    getSpotifyUserPlaylists,
    {
      enabled: !!spotifyClient,
    }
  );
};

export default useSpotifyUserPlaylists;
