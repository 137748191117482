import React from "react";
import { Box, IBoxProps } from "native-base";

import useContextualColor from "../hooks/useContextualColor";

const Card: React.FC<IBoxProps> = ({ children, ...props }) => {
  const bg = useContextualColor("card.background");
  return (
    <Box bg={bg} p="4" {...props}>
      {children}
    </Box>
  );
};

export default Card;
