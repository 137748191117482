import { ITextProps } from "native-base";

import { contextual } from "./colors";
import { BaseStyleArguments, TextProps } from "../types/ThemeTypes";

// export const defaultProps: Required<TextProps> = {
//   variant: "p",
// };

type TextBaseStyleArguments = BaseStyleArguments<TextProps>;
const TextTheme = {
  // defaultProps,
  baseStyle: ({ colorMode }: TextBaseStyleArguments) => ({
    color: `contextual.foreground.${colorMode}`,
    fontFamily: "body",
    fontSize: "md",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "7",
  }),
  variants: {
    h1: ({ colorMode }: TextBaseStyleArguments) => ({
      color: `contextual.h1.${colorMode}`,
      fontFamily: "heading",
      fontSize: "3xl",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "10",
      mt: 30,
      mb: 35,
    }),
    h2: ({ colorMode }: TextBaseStyleArguments) => ({
      color: `contextual.h2.${colorMode}`,
      fontFamily: "heading",
      fontSize: "2xl",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "9",
      mt: 30,
      mb: 15,
    }),
    h3: ({ colorMode }: TextBaseStyleArguments) => ({
      color: `contextual.h3.${colorMode}`,
      fontFamily: "heading",
      fontSize: "xl",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "6",
      mt: 20,
      mb: 8,
    }),
    h4: ({ colorMode }: TextBaseStyleArguments) => ({
      color: `contextual.h4.${colorMode}`,
      fontFamily: "heading",
      fontSize: "md",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "7",
      mt: 20,
      mb: 8,
    }),
    h5: ({ colorMode }: TextBaseStyleArguments) => ({
      color: `contextual.h5.${colorMode}`,
      fontFamily: "body",
      fontSize: "md",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "6",
      mt: 20,
      mb: 8,
    }),
    p: () => ({
      mb: 26,
    }),
  },
};

export default TextTheme;
